import ErrorBannerLayout from "./ErrorBannerLayout"

export default function VerificationErrorBanner() {
  return (
    <ErrorBannerLayout>
      An error occurred during account verification.
      <br />
      Please sign in to try again.
    </ErrorBannerLayout>
  )
}
