import { Link } from "@remix-run/react"

export default function SignUpHelpMessage({
  queryString,
}: {
  queryString: string
}) {
  return (
    <span className="text-body-copy leading-7">
      Don{"'"}t have an account?{" "}
      <Link
        to={`/register?${queryString}`}
        className="text-primary-600 hover:underline underline-offset-4"
      >
        Sign up now
      </Link>
    </span>
  )
}
