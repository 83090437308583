import SuccessBannerLayout from "./SuccessBannerLayout"

export default function SuccessfulRegisterBanner() {
  return (
    <SuccessBannerLayout>
      Account successfully created.
      <br />
      Please check your email to verify the account.
    </SuccessBannerLayout>
  )
}
