import {
  redirect,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from "@remix-run/server-runtime"
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useNavigation,
} from "@remix-run/react"
import { WorkOS } from "@workos-inc/node"
import { json } from "@remix-run/server-runtime"
import {
  authenticateWithPassword,
  sendEmailActivation,
} from "~/utils/authHelpers.server"
import {
  checkEmail,
  checkPassword,
  getStandardAuthParams,
} from "~/utils/parameters.server"
import {
  buildAuthQueryString,
  type AuthStandardParameters,
} from "~/utils/authUri"
import { getEnvironment } from "~/utils/environment.server"
import SubmitButton from "~/components/SubmitButton"
import Layout from "~/components/Layout"
import SignUpHelpMessage from "~/components/SignUpHelpMessage"
import SuccessfulRegisterBanner from "~/components/banners/SuccessfulRegisterBanner"
import VerificationErrorBanner from "~/components/banners/VerificationErrorBanner"
import { z } from "zod"
import LigInput from "~/components/LigInput"
import { zodResolver } from "@hookform/resolvers/zod"
import { getValidatedFormData, useRemixForm } from "remix-hook-form"
import { noEmptyString } from "~/utils/validate-password"
import cookie from "cookie"
import InvalidAuthorizeBanner from "~/components/banners/InvalidAuthorizeBanner"
import CheckEmailBanner from "~/components/banners/CheckEmailBanner"

const defaultActionData = {
  errorType: null,
  errorMessage: null,
  systemErrorMessage: null,
  success: false,
  checkEmail: null,
}

const schema = z.object({
  email: z.preprocess(
    noEmptyString,
    z
      .string({ required_error: "Email address is required" })
      .email("Email address is invalid"),
  ),
  password: z.preprocess(
    noEmptyString,
    z.string({ required_error: "Password is required" }),
  ),
})

type FormData = Zod.infer<typeof schema>

const resolver = zodResolver(schema)

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url)
  const { redirectUri, grantType, codeChallenge, state, nonce } =
    getStandardAuthParams(url)
  const registrationSuccess =
    url.searchParams.get("registrationSuccess") !== null
  const verifyEmailNeeded = url.searchParams.get("verifyEmailNeeded") !== null
  const verifyEmailError = url.searchParams.get("verifyEmailError") !== null

  const queryString = buildAuthQueryString<AuthStandardParameters>({
    redirectUri,
    grantType,
    codeChallenge,
    state,
    nonce,
  })
  return json({
    redirectUri,
    queryString,
    registrationSuccess,
    verifyEmailNeeded,
    verifyEmailError,
  })
}

export async function action({ request }: ActionFunctionArgs) {
  const cookieHeader = request.headers.get("Cookie") || ""
  const cookies = cookie.parse(cookieHeader)
  const anonymousId = cookies.ajs_anonymous_id || undefined
  const url = new URL(request.url)
  const { redirectUri, grantType, codeChallenge, state, nonce } =
    getStandardAuthParams(url)

  const { errors: formErrors, data } = await getValidatedFormData<FormData>(
    request,
    resolver,
  )

  if (formErrors) {
    return json({
      ...defaultActionData,
      errorMessage: "Validation failed, please try again",
    })
  } else {
    const email = checkEmail(data.email)
    const password = checkPassword(data.password)

    const workos = new WorkOS(getEnvironment().WORKOS_API_KEY)

    const users = await workos.userManagement.listUsers({
      email,
      limit: 1,
    })
    const foundUser = users.data[0]

    if (foundUser && !foundUser.emailVerified) {
      await sendEmailActivation({
        workos,
        email,
        request,
      })

      return json({ ...defaultActionData, checkEmail: true })
    }

    const result = await authenticateWithPassword(
      {
        workos,
        email,
        password,
        anonymousId,
        host: url.host,
        origin: url.origin,
        redirectUri,
        grantType,
        codeChallenge,
        state,
        nonce,
        request,
      },
      true, // NOTE: change this to false when we no longer want to import from Azure (or remove it and the code that would execute if it's true)
    )
    if ("uri" in result) {
      if (result?.setCookieHeader) {
        return redirect(result.uri, {
          headers: { "Set-Cookie": result.setCookieHeader },
        })
      } else {
        return redirect(result.uri)
      }
    } else {
      return result
    }
  }
}

export default function Authorize() {
  const navigation = useNavigation()
  const { queryString, registrationSuccess, verifyEmailError } =
    useLoaderData<typeof loader>()
  const actionData = useActionData<typeof action>()

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useRemixForm<FormData>({ mode: "onChange", resolver })

  const submitErrors = !!actionData?.errorMessage

  return (
    <Layout
      title="Sign In"
      helpMessage={<SignUpHelpMessage queryString={queryString} />}
    >
      {registrationSuccess && !submitErrors && <SuccessfulRegisterBanner />}
      {submitErrors && <InvalidAuthorizeBanner />}
      {actionData?.checkEmail && <CheckEmailBanner />}
      {verifyEmailError && !submitErrors && <VerificationErrorBanner />}
      <Form
        onSubmit={handleSubmit}
        method="post"
        className="w-full flex flex-col gap-6 mb-6"
      >
        <LigInput
          labelText="Email Address"
          errorMessage={errors.email?.message}
          aria-required={true}
          type="email"
          {...register("email")}
        />
        <LigInput
          labelText="Password"
          errorMessage={errors.password?.message}
          aria-required={true}
          type="password"
          forgotPassword={
            <Link
              to={`/send-reset-password?${queryString}`}
              className="text-primary-600 leading-7 hover:underline underline-offset-4"
            >
              Forgot your password?
            </Link>
          }
          {...register("password")}
        />
        <SubmitButton
          text="Sign In"
          isLoading={
            navigation.state === "submitting" || navigation.state === "loading"
          }
        />
      </Form>
    </Layout>
  )
}
