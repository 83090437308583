import ErrorBannerLayout from "./ErrorBannerLayout"

export default function InvalidAuthorizeBanner() {
  return (
    <ErrorBannerLayout>
      Email address or password is incorrect.
      <br />
      Please try again.
    </ErrorBannerLayout>
  )
}
